@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Source+Code+Pro&display=swap);
/* @font-face {
  font-family: "SF-Pro";
  src: local("neon"), url(./fonts/SF-Pro.woff2) format('woff2');
}

@font-face {
  font-family: "SF-Pro-Heavy";
  src: local("neon"), url(./fonts/SF-Pro-Heavy.woff2) format('woff2')
} */

@font-face {
    font-family: 'SF Pro';
    src: url(/static/media/SFPro-Regular.5bc0e1a2.woff2) format('woff2'),
        url(/static/media/SFPro-Regular.6743f039.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url(/static/media/SFProDisplay-Black.44204515.woff2) format('woff2'),
        url(/static/media/SFProDisplay-Black.1fc913e3.woff) format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

:root {
  --primary-grey: #ccd1d9;

  --vs-text: #abb2bf;
  /* --primary-vs-grey: #64676f;  */
  /* --vs-background: #282c34; */

  --primary-light-dark: #1d1d1d;

  --primary-light-grey: #909096; /* inputs */
  --primary-medium-grey: #757575; /* scroll indicator */
  --primary-dark-grey: #515152; /* inputs & timeline */
  
  /* color scheme */
  --primary-orange: #f42e00;
  --primary-light-blue: #c8eef5; 
  --primary-aqua: #99fffe;
  --primary-navy: #042549;
  
  /* contact */
  --primary-contact-text: #a7a7af;
  --contact-dark-grey: #424245;
}

body {
  font-family: 'SF Pro', monospace;
}

.App {
  text-align: center;
}


/********************************* LOADER *********************************/
.loader{
  background-color: #f8f9fa;
  height: 100vh;
  width: 100vw;
  text-align: center;
  position: relative;
}

.loader-content{
  position: absolute;
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}


.loader-text{
  font-family: 'Roboto Mono', monospace !important;
  font-size: 1rem;
  color: black;
  margin-bottom: 1rem;
}



/********************************* NAVBAR *********************************/
nav {
  z-index: 1;
  font-family: 'Source Code Pro', monospace !important;
}

/*------------------------ NAV LINKS ------------------------*/
nav a.nav-link {
  font-weight: 500;
  font-size: .9rem;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  color: #042549 !important;
  color: var(--primary-navy) !important;
  margin: 0 0.5rem;
}

.navbar-nav > li > a.active, .navbar-nav > li > a.active:focus{
  color: #f42e00 !important;
  color: var(--primary-orange) !important;
}

.navbar-nav > li > a:hover {
  color: #f42e00 !important;
  color: var(--primary-orange) !important;
}

#resume-btn{
  border: solid 1px #042549;
  border: solid 1px var(--primary-navy);
  color: #042549 !important;
  color: var(--primary-navy) !important;
  padding: .4rem .55rem !important;
  border-radius: 4px;
  display: inline-block;
}

#resume-btn:hover{
  border: solid 1px #f8f9fa;
  background: #99fffe;
  background: var(--primary-aqua);
  transition: .3s ease-in-out;
}

@media (max-width: 1090px) {
  nav ul li {
    text-align: center !important
  }

  #resume-btn{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    margin-bottom: .25rem !important;
  }
}


/********************************* HOME *********************************/
.home-wrapper {
  position: relative;
  height: 100vh
}

.home-wrapper::before {    
  content: "";
  background-image: url(https://res.cloudinary.com/dfpkfgg80/image/upload/v1623708486/home-min_2_ukxoyn.jpg);
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.14;
}
  
.home-wrapper::after{
  content: "";
  background-color: #1d1d1d;
  background-color: var(--primary-light-dark);
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.2;
}

.main-info{
  display: -webkit-flex;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  z-index: 3;
} 


/*------------------------ GLITCH ------------------------*/

.glitch-container {
  margin-bottom: 3rem;
}

.glitch-container h1 {
  font-family: 'Source Code Pro', monospace !important;
  color: black;
  font-size: 5em; 
  letter-spacing: 0.0375rem;

  font-weight: 400;
  /*Create overlap*/
  
  margin: 0;
  line-height: 0;
  /*Animation*/
  
  -webkit-animation: glitch1 2.5s infinite;
  
          animation: glitch1 2.5s infinite;
}

@media screen and (max-width: 600px) {
  .glitch-container {
    margin-bottom: 2rem;
  }

  .glitch-container h1 {
    font-size: 3em; 
  }
}

@media screen and (max-width: 360px) {
  .glitch-container {
    margin-bottom: 1rem;
  }

  .glitch-container h1 {
    font-size: 2em; 
  }
}

.glitch-container h1:nth-child(2) {
  color: #67f3da;
  -webkit-animation: glitch2 2.5s infinite;
          animation: glitch2 2.5s infinite;
}

.glitch-container h1:nth-child(3) {
  color: #f16f6f;
  -webkit-animation: glitch3 2.5s infinite;
          animation: glitch3 2.5s infinite;
}
/*Keyframes*/

@-webkit-keyframes glitch1 {
  0% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
  7% {
    -webkit-transform: skew(-0.5deg, -0.9deg);
            transform: skew(-0.5deg, -0.9deg);
    opacity: 0.75;
  }
  10% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
  27% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
  30% {
    -webkit-transform: skew(0.8deg, -0.1deg);
            transform: skew(0.8deg, -0.1deg);
    opacity: 0.75;
  }
  35% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
  52% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
  55% {
    -webkit-transform: skew(-1deg, 0.2deg);
            transform: skew(-1deg, 0.2deg);
    opacity: 0.75;
  }
  50% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
  72% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
  75% {
    -webkit-transform: skew(0.4deg, 1deg);
            transform: skew(0.4deg, 1deg);
    opacity: 0.75;
  }
  80% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
  100% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
}

@keyframes glitch1 {
  0% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
  7% {
    -webkit-transform: skew(-0.5deg, -0.9deg);
            transform: skew(-0.5deg, -0.9deg);
    opacity: 0.75;
  }
  10% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
  27% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
  30% {
    -webkit-transform: skew(0.8deg, -0.1deg);
            transform: skew(0.8deg, -0.1deg);
    opacity: 0.75;
  }
  35% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
  52% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
  55% {
    -webkit-transform: skew(-1deg, 0.2deg);
            transform: skew(-1deg, 0.2deg);
    opacity: 0.75;
  }
  50% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
  72% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
  75% {
    -webkit-transform: skew(0.4deg, 1deg);
            transform: skew(0.4deg, 1deg);
    opacity: 0.75;
  }
  80% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
  100% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
}

@-webkit-keyframes glitch2 {
  0% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  7% {
    -webkit-transform: translate(-2px, -3px);
            transform: translate(-2px, -3px);
    opacity: 0.5;
  }
  10% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  27% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  30% {
    -webkit-transform: translate(-5px, -2px);
            transform: translate(-5px, -2px);
    opacity: 0.5;
  }
  35% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  52% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  55% {
    -webkit-transform: translate(-5px, -1px);
            transform: translate(-5px, -1px);
    opacity: 0.5;
  }
  50% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  72% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  75% {
    -webkit-transform: translate(-2px, -6px);
            transform: translate(-2px, -6px);
    opacity: 0.5;
  }
  80% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  100% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
}

@keyframes glitch2 {
  0% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  7% {
    -webkit-transform: translate(-2px, -3px);
            transform: translate(-2px, -3px);
    opacity: 0.5;
  }
  10% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  27% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  30% {
    -webkit-transform: translate(-5px, -2px);
            transform: translate(-5px, -2px);
    opacity: 0.5;
  }
  35% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  52% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  55% {
    -webkit-transform: translate(-5px, -1px);
            transform: translate(-5px, -1px);
    opacity: 0.5;
  }
  50% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  72% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  75% {
    -webkit-transform: translate(-2px, -6px);
            transform: translate(-2px, -6px);
    opacity: 0.5;
  }
  80% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  100% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
}

@-webkit-keyframes glitch3 {
  0% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  7% {
    -webkit-transform: translate(2px, 3px);
            transform: translate(2px, 3px);
    opacity: 0.5;
  }
  10% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  27% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  30% {
    -webkit-transform: translate(5px, 2px);
            transform: translate(5px, 2px);
    opacity: 0.5;
  }
  35% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  52% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  55% {
    -webkit-transform: translate(5px, 1px);
            transform: translate(5px, 1px);
    opacity: 0.5;
  }
  50% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  72% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  75% {
    -webkit-transform: translate(2px, 6px);
            transform: translate(2px, 6px);
    opacity: 0.5;
  }
  80% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  100% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
}

@keyframes glitch3 {
  0% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  7% {
    -webkit-transform: translate(2px, 3px);
            transform: translate(2px, 3px);
    opacity: 0.5;
  }
  10% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  27% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  30% {
    -webkit-transform: translate(5px, 2px);
            transform: translate(5px, 2px);
    opacity: 0.5;
  }
  35% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  52% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  55% {
    -webkit-transform: translate(5px, 1px);
            transform: translate(5px, 1px);
    opacity: 0.5;
  }
  50% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  72% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  75% {
    -webkit-transform: translate(2px, 6px);
            transform: translate(2px, 6px);
    opacity: 0.5;
  }
  80% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
  100% {
    -webkit-transform: none;
            transform: none;
    opacity: 0.15;
  }
}

/*------------------------ TYPED TEXT ------------------------*/
.typed-text{
  font-family: 'Roboto Mono', monospace !important;
  font-size: 1rem;
  color: black;
  margin-bottom: 1rem;
}

@media screen and (max-width: 600px) {
  .typed-text{
    font-size: .85rem;
    color: black;
    margin-bottom: .5rem;
  }
}

@media screen and (max-width: 360px) {
  .typed-text{
    font-size: .5rem;
    color: black;
  }
}

/*------------------------ BUTTON ------------------------*/
.btn-main-offer{
  border: 1px solid; 
  border-radius: 4px !important;
  font-family: 'Source Code Pro', monospace !important;
  text-transform: uppercase;
  border-radius: 0;
  padding: .5rem;
  color: black;
}

.btn-main-offer:hover{
  border: 1px solid #f42e00;
  border: 1px solid var(--primary-orange); 
  text-decoration: none;
  background-color: #f42e00;
  background-color: var(--primary-orange);
  color: white;
  transition: .2s ease-in-out;
}

/*------------------------ SCROLL ------------------------*/

  .demo a {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 2;
    display: inline-block;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%);
    text-decoration: none;
    transition: opacity .3s;
  }
  .demo a:hover {
    opacity: .5;
  }

  #scroll a {
    padding-top: 70px;
  }
  #scroll a span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: .13rem solid #757575;
    border-left: .13rem solid var(--primary-medium-grey);
    border-bottom: .13rem solid #757575;
    border-bottom: .13rem solid var(--primary-medium-grey);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: scroll 1.5s infinite;
    animation: scroll 1.5s infinite;
    box-sizing: border-box;
  }
  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: rotate(-45deg) translate(0, 0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(-45deg) translate(-20px, 20px);
      opacity: 0;
    }
  }
  @keyframes scroll {
    0% {
      -webkit-transform: rotate(-45deg) translate(0, 0);
              transform: rotate(-45deg) translate(0, 0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(-45deg) translate(-20px, 20px);
              transform: rotate(-45deg) translate(-20px, 20px);
      opacity: 0;
    }
  }


/********************************* ABOUT *********************************/
.photo-wrap{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.profile-img{
  width: 18rem;
  /* padding: 0.3rem; */
  border-radius: 50%;
  border: 2px solid #042549;
  border: 2px solid var(--primary-navy)
}

.abt-heading{
  color: #99fffe;
  color: var(--primary-aqua);
  font-family: 'SF Pro Display', monospace !important;
  text-transform: uppercase;
}

.overlap{
  margin-left: -204px;
  color: #042549;
  color: var(--primary-navy);
}

.about{
  font-size: .85rem;
}

.abt-icon{
  text-decoration: none;
  color: #515152;
  color: var(--primary-dark-grey);
  cursor: pointer;
  font-family: 'SF Pro';
}

.abt-icon:hover{
  text-decoration: none;
  color: #157ffb;
  transition: .3s ease-in-out;
}

@media (max-width: 1090px) {
  .abt-heading{
    text-align: center;
  }
}

@media(max-width: 468px) {
  .profile-img{
    width: 10rem;
  }
}

@media(max-width: 360px) {
  .abt-heading{
    font-size: 2rem
  }

  .overlap{
    margin-left: -163px;
  }

  .about{
    font-size: .75rem;
  }
}

/*------------------------ SKILLS ------------------------*/
.table{
  width: 80% !important;
  margin: 0 auto;
  font-family: 'Roboto Mono', monospace !important;
}

.table td{
  border: none !important;
  padding: 0 !important;
  font-size: 0.7rem !important;
}

.skill-arrow{
  color: #f42e00;
  color: var(--primary-orange)
}

/********************************* EXPERIENCE *********************************/
.experience{
  padding: 0.7rem;
}

.experience-wrapper{
  position: relative;
  padding: 2rem 0;
  padding-bottom: 1rem;
  overflow: hidden;
  margin-bottom: 3rem;
}

.experience h1{
  text-transform: uppercase;
  text-align: center;
  color: #99fffe;
  color: var(--primary-aqua);
  font-family: 'SF Pro Display', monospace !important;
 }

#exp-overlap{
  margin-left: -411px;
  color: #042549;
  color: var(--primary-navy);
}

@media(min-width: 780px){
  .experience h1{
    font-size: 3.2rem
   }
  
  #exp-overlap{
    margin-left: -526px;
  } 
 }

 @media(max-width: 480px){
  .experience h1{
    font-size: 1.8rem
  }

  #exp-overlap{
      margin-left: -296px;
  }
 }

 @media(max-width: 360px){
  .experience h1{
    font-size: 1.5rem
  }

  #exp-overlap{
      margin-left: -246.5px;
  }
 }

.experience-wrapper:before{
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: #424245;
  background: var(--contact-dark-grey);
  z-index: 1;
}

.timeline-block{
  width: calc(50% + 0.5rem);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  clear: both;
}

.timeline-block-right{
  float: right;
}

.timeline-block-left{
  float: left;
  direction: rtl;
}

.marker{
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.37rem solid #042549;
  border: 0.37rem solid var(--primary-navy);
  background: #99fffe;
  background: var(--primary-aqua);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content{
  width: 95%;
  padding: 0 0.9375rem;
  color:#515152;
  color:var(--primary-dark-grey);
  direction: ltr;
}

.timeline-content h5{
  margin-top: 0.3125rem;
  color: #042549;
  color: var(--primary-navy);
  font-weight: 580;
  font-size: 1.3rem;
  margin-bottom: .1rem;
}

.employer{
  margin: 0;
  margin-top: .3rem;
  line-height: 1 !important;
  color: #f42e00;
  color: var(--primary-orange);
  font-weight: 570;
}

.employer a{
  text-decoration: none;
  cursor: pointer;
  position:relative;
  color: #f42e00;
  color: var(--primary-orange);
}

.employer a::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top: 100%;
  pointer-events: none;
  background: currentColor;
  -webkit-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
  -webkit-transform: scale3d(0, 1.2, 1);
          transform: scale3d(0, 1.2, 1);
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

.employer a:hover::before {
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
  -webkit-transform: scale3d(1, 1.2, 1);
          transform: scale3d(1, 1.2, 1);
}

.dates-employed{
  font-family: 'Roboto Mono', monospace;
  font-size: .8rem;
  word-spacing: -.45ch;
}

.timeline-content p{
  font-size: .785rem;
  margin-top: .1rem;
}

@media(max-width: 768px){
  .experience .container{
    padding-left: 0;
  }
  .experience-wrapper:before{
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }

  .timeline-block-right{
    float: none;
    width: 100%;
  }

  .timeline-block-left{
    float: none;
    direction: ltr;
    width: 100%;
  }

}

 /********************************* PORTFOLIO *********************************/
 .portfolio-wrapper{
   position: relative;
   padding: 3rem 0;
   padding-bottom: 6rem;
  }
  
  @media(min-width: 768px){
   .portfolio-wrapper{
     padding: 4rem 0;
     padding-bottom: 8rem;
   }
  }

 .portfolio-wrapper::before {    
  content: "";
  background-image: url(https://res.cloudinary.com/dfpkfgg80/image/upload/v1623708451/code-background-min_1_xyhuhx.jpg);
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.04;
  z-index: -2;
}

 .popupbox-content{
   text-align: center;
 }

 .portfolio-wrapper h1{
    color: #99fffe;
    color: var(--primary-aqua);
    font-family: 'SF Pro Display', monospace !important;
    margin-bottom: 0 !important;
 }

 #portfolio-overlap{
    margin-left: -353px;
    color: #042549;
    color: var(--primary-navy);
 }

 .portfolio-image{
   width: 14.5rem;
   height: 8.8rem;
   border: 1px solid #f42e00;
   border: 1px solid var(--primary-orange);
   padding: 0;
 }

 .portfolio-image-box{
   position: relative;
   margin: 1rem;
 }

 .overflow {
   position: absolute;
   opacity: 0;
   background: black;
   width: 14.5rem;
   height: 8.8rem;
   top: 0;
   border: 1px solid #99fffe;
   border: 1px solid var(--primary-aqua);
   cursor: pointer;
 }

 .portfolio-image-box:hover .overflow {
   opacity: .6;
   transition: 0.3s ease-in-out;
 }

 .portfolio-image-box:hover .portfolio-image {
  border: 1px solid #99fffe;
  border: 1px solid var(--primary-aqua);
 }

 .portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: #99fffe;
  color: var(--primary-aqua);
  font-size: 1.5rem;
 }

 .portfolio-image-box:hover .portfolio-icon{
  opacity: .7;
  transition: 0.3s ease-in-out;
  cursor: pointer;
 }

 @media(min-width: 780px){
  .portfolio-wrapper h1{
    font-size: 3.2rem
  }

  #portfolio-overlap{
      margin-left: -452px;
  }
  
 }

 @media(max-width: 480px){
  .portfolio-wrapper h1{
    font-size: 1.8rem
  }

  #portfolio-overlap{
      margin-left: -255px;
  }
  
 }


/*------------------------ POPUP ------------------------*/
.popupbox-wrapper{
  margin-top: 4rem;
}

.popupbox-titleBar{
  padding-top: 1px !important;
  padding-bottom: 1px !important
}

.popupbox-btn--close{
  top: 4px !important;
}

.popupbox-content{
  padding: .6rem 1rem !important;
}

.portfolio-image-popupbox {
  width: 45rem;
  padding: 0.5rem; 
  border: .025rem solid #ccd1d9; 
  border: .025rem solid var(--primary-grey);
  border-radius: .25rem;
}

.popupbox-titleBar span{
  font-weight: 580;
  font-size: 1.5rem;
}

.popup-desc{
  font-size: .88rem;
  width: 45rem;
  margin: .5rem 0;
  margin-bottom: .3rem;
}

.tech-used{
  margin-bottom: .3rem;
  font-size: .7rem;
  font-family: 'Roboto Mono', monospace;
}

.tech-used span{
  margin: 0 .8rem
}

.tech-used span::before{
  content: "ᐅ";
  margin-right: .2rem;
  color: #f42e00;
  color: var(--primary-orange);
}

.hyper-link {
  cursor: pointer;
  color: black;
  margin: 0 1rem;
  font-size: 1rem;
}

.hyper-link:hover{
  text-decoration: none;
  transition: 0.3s ease-in-out;
  color: #f42e00;
  color: var(--primary-orange);
}

.popup-icons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.popup-icon {
  margin-right: .25rem;
  color: #157ffb
}

.popup-links span{
  line-height: 80%;
}

/* WIDTH */

@media(max-width: 1050px){

  .portfolio-image-popupbox{
    width: 90%;
  }

  .popup-desc{
    width: 100%;
    font-size: .75rem;
  }

  .tech-used{
    font-size: .58rem;
  }
  
  .tech-used span{
    margin: 0 .7rem
  }
}

@media(max-width: 768px){

  .portfolio-image-popupbox{
    width: 100%;
  }

  .popup-desc{
    font-size: .61rem;
    width: 100%;
    margin: .25rem 0;
  }

  .tech-used{
    font-size: .45rem;
  }

  .tech-used span::before{
    margin-right: .1rem;
  }
  
  .tech-used span{
    margin: 0 .25rem
  }

  .popup-links{
    font-size: .8rem;
  }
}

@media(max-width: 550px){
  .popup-links{
    font-size: .7rem;
  }

}


/* HEIGHT */

@media(max-height: 640px){
  .popupbox-wrapper{
    width: 60%;
  }

  .popupbox-content{
    text-align: center;
  }


  .portfolio-image-popupbox{
    width: 60%;
  }

  .popup-desc {
   width: auto;
  }

  .popup-links{
    font-size: .7rem;
  }
}

@media(max-height: 570px){

  .portfolio-image-popupbox{
    width: 40%;
  }
}



 /********************************* CONTACT *********************************/
.contact {
  padding: 0;
  padding-top: 3rem;
  position: relative
}

.contact::before{
  content: "";
  z-index: -2;
  background-color: #c8eef5;
  background-color: var(--primary-light-blue);
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: .2;
}

.contact h1{
  padding: 1.3rem 0;
  text-transform: uppercase;
  text-align: center;
  color: #99fffe;
  color: var(--primary-aqua);
  font-family: 'SF Pro Display', monospace !important;
}

#contact-overlap{
  margin-left: -256px;
  color: #042549;
  color: var(--primary-navy);
}

@media(max-width: 768px){

  .contact{
    padding-bottom: 3rem;
  }

}

@media(min-width: 780px){
  .contact h1{
    font-size: 3.2rem
   }
  
  #contact-overlap{
    margin-left: -327.3px;
  }
  
 }

 @media(max-width: 480px){
  .contact h1{
    font-size: 2.1rem
  }

  #contact-overlap{
      margin-left: -215.3px;
  }
  
 }

.contact p{
  color: #424245;
  color: var(--contact-dark-grey);
  font-size: 1.2rem;
}

/*------------------------ INPUTS ------------------------*/
.contact input, .contact textarea{
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: .125rem solid #a7a7af;
  border-bottom: .125rem solid var(--primary-contact-text);
  border-radius: 0;
  color: #042549;
  color: var(--primary-navy);
  outline: 0 !important; 
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contact input::-webkit-input-placeholder, .contact textarea::-webkit-input-placeholder{
  color: #a7a7af;
  color: var(--primary-contact-text);
}

.contact input:-ms-input-placeholder, .contact textarea:-ms-input-placeholder{
  color: #a7a7af;
  color: var(--primary-contact-text);
}

.contact input::placeholder, .contact textarea::placeholder{
  color: #a7a7af;
  color: var(--primary-contact-text);
}

.contact .container{
  padding: 2.45rem 0 4rem;
}

.contact-input-area{
  -webkit-justify-content: center;
          justify-content: center;
}

.contact input:focus, .contact textarea:focus{
  background: transparent;
  color: #042549;
  color: var(--primary-navy);
  border-bottom: .125rem solid #a7a7af;
  border-bottom: .125rem solid var(--primary-contact-text);
}

.contact input:not(:-ms-input-placeholder), .contact textarea:not(:-ms-input-placeholder){
  border-bottom: .125rem solid #042549;
  border-bottom: .125rem solid var(--primary-navy);
}

.contact input:not(:placeholder-shown), .contact textarea:not(:placeholder-shown){
  border-bottom: .125rem solid #042549;
  border-bottom: .125rem solid var(--primary-navy);
}

.contact textarea{
 height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;

}

.line{
  position: relative;
  top: -0.75rem;
  width: 0;
  height: .125rem;
  background: #042549;
  background: var(--primary-navy);
  display: inline-block;
}

input:focus+.line, textarea:focus+.line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message:empty{
  padding: 0
}

.error-message {
  color: white;
  background: #f42e00;
  background: var(--primary-orange);
  text-align: left;
  padding: 0 .2rem;
}

.success-message {
  font-weight: 570;
  color: green;
}

.remove-msg{
  opacity: 0;
  transition: visibility 0s 2s, opacity 4s linear
}

@media(max-width: 768px){
  .contact {
    overflow-x: hidden;
  }

  .contact .container{
    padding: 1rem;
  }

  .contact p{
    padding: 0 .4rem;
    margin-bottom: 2rem;
    font-size: 1rem
  }

  .success-message {
    font-size: 1rem
  }
}

@media(max-width: 610px){
  .contact p{
    font-size: .9rem;
    padding: 0 .5rem
  }

  .success-message {
    font-size: .8rem
  }
}

 /********************************* FOOTER *********************************/
.footer{
  background: #03182f;
  color: white;
  font-family: 'Source Code Pro', monospace;
  padding: 1.5rem;
  padding-bottom: .7rem;
}

.footer p{
  margin-bottom: 0;
}

.footer-contact{
  font-size: .81rem
}

.footer a{
  text-decoration: none;
  color: white;
  cursor: pointer;
  font-family: 'SF Pro';
  position:relative;
}

.footer a::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top: 100%;
  pointer-events: none;
  background: currentColor;
  -webkit-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
  -webkit-transform: scale3d(0, 1.2, 1);
          transform: scale3d(0, 1.2, 1);
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

.footer a:hover::before {
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
  -webkit-transform: scale3d(1, 1.2, 1);
          transform: scale3d(1, 1.2, 1);
}

.footer-links{
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.footer-contact-icon{
  margin-right: .25rem;
}

.copyright {
  padding-top: .4rem;
  font-size: .61rem;
}

.footer-icon{
  font-size: 1.5rem;
  margin: 0 .5rem;
}

@media(max-width: 620px){

  .footer-contact{
    font-size: .7rem;
  }

  .copyright {
    font-size: .51rem;
  }
}

@media(max-width: 480px){
  .footer-contact-icon{
    display: none;
  }
}

@media(max-width: 390px){

  .footer-flex{
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }

  .footer-col{
    margin-bottom: .3rem;
  }

  .footer-contact{
    font-size: .8rem;
  }

  .footer-contact-icon{
    display: unset;
  }

  .footer-links{
    -webkit-justify-content: center;
            justify-content: center;
  }

  .copyright {
    padding-top: .2rem;
    font-size: .6rem;
  }
}
